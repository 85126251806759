<template>
    <!-- 面包屑 -->
    <div>
        <div class="breadcrumb">
            <div class="text" v-for="(item, index) in routerList" :key="index" @click="jump(item)"
                :style="index == routerList.length - 1 ? 'color:#F58030' : ''">
                {{ index == routerList.length - 1 ? item : item + '&nbsp;/&nbsp;' }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        routerList: {
            type: Array,
            default: () => {
                let list = ['平台台账']//示例
                return list
            }
        }
    },
    data() {
        return {

        }
    },
    methods: {
        jump(name) {
            this.$router.push(this.$main.RouerPathList[name].path)
        }
    },
    created() {

    }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    display: flex;
    align-items: center;
    width: auto;
    height: 22px;

    .text {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        ;
        cursor: pointer;
    }
}
</style>